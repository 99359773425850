<template>
  <div class="mmdw">
    <div class="main">
      <div class="header">
        <div class="logo" style="display: flex;align-items: center;">
          <!-- <span v-if="this.$store.state.isLogin" class="outLogin"><span style="text-decoration: underline;"
                            @click="handleUserCenter">个人中心</span><el-divider direction="vertical"></el-divider><span
                            @click="relogin">退出</span></span>-->
          <div style="display: flex; margin-left: 75px;align-items: center;">
            <div>
              <div style="display: flex;align-items: center;">
                <img src="@/assets/logo4.png" style="width: 15px" />
                <div>
                  <div style="font-size: 13px; margin-left: 5px">浙江文化产权交易所</div>
                </div>
              </div>
              <div style="display: flex;align-items: center;">
                <img src="@/assets/logo3.png" style="width: 15px" />
                <div>
                  <div style="font-size: 13px; margin-left: 5px">湖北华中文化产权交易所</div>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center; width: 180px; margin-left: 30px; cursor: pointer; font-weight: bold" @click="handleGoIndex">
              <!-- <el-image :src="require('../assets/mmdw.png')" style="width: 30px; height: 30px; margin-right: 5px" /> -->
              文旅要素交易平台
              <div>湖北专区</div>
            </div>

            <div class="item" style="margin-left: 50px;">
              <span style="cursor: pointer" @click="toPushRouter(item)" v-for="(item, index) in btnList" :key="index" :class="{ xia: item.id == idx }">
                {{ item.label }}
              </span>
            </div>
            <div class="input_box" style="margin-left: 100px;">
              <el-input autocomplete="off" v-model.trim="ProductName" style="width: 250px; margin-left: 15px" placeholder="产品编码/产品名称" clearable>
                <el-button slot="append" icon="el-icon-search" @click.native="searchProduct"></el-button>
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="body">
        <keep-alive include="Index-name">
          <router-view />
          <!-- <RouterView /> -->
        </keep-alive>
      </div>

      <div class="h-copyright-root">
        <div class="container">
          <div class="copyright">
            <span>--Copyright@ 2024 All Rights Reserved--</span><br />
            <span
              >免责声明：本网站所刊登、转载的各种图片、稿件是为传播更多的信息，本网不承担此类稿件侵权行为的连带责任。</span
            ><br />
            <span
              >版权所有：湖北省颐和天下经济文化有限公司 电话：027- 85800188
              邮箱：610933058@qq.com</span
            ><br />
            <!-- 经营许可证备案号：XXXXXXXXXX 工信部备案 -->
            <span
              >工信部备案：<a
                style="color:#fff; text-decoration: underline"
                href="http://beian.miit.gov.cn"
                target="_blank"
                >鄂ICP备2023019558号-2</a
              >；公安备案号：<img
                style="width: 15px; height: 15px; margin-bottom: -3px"
                src="@/assets/beiAn.png"
                alt=""
              /><a
                style="color:#fff; text-decoration: underline"
                href="https://beian.mps.gov.cn/#/query/webSearch?code=42010402001616"
                rel="noreferrer"
                target="_blank"
                >鄂公网安备42010402001616</a
              >；</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import { rebackLogin } from "@/util";
export default {
  // components: { RouterView },
  name: "Index-name",
  data() {
    return {
      ProductName: "",
      btnList: [
        { id: 1, label: "首页", path: "/" },
        { id: 2, label: "产品中心", path: "/super" },
        { id: 3, label: "商城公告", path: "/prd" },
        { id: 4, label: "商城指南", path: "/guide" },
        { id: 5, label: "文旅资讯", path: "/consultation" },
      ],
      idx: 1,
    };
  },
  watch: {
    "$router.currentRoute.path": {
      handler(n) {
        this.idx = this.btnList.filter(v => v.path == n)[0] ? this.btnList.filter(v => v.path == n)[0].id : null;
      },
      immediate: true,
    },
    "$store.state.index": {
      handler(n) {
        this.$router.afterEach(item => {
          this.idx = this.btnList.filter(v => v.path == item.path)[0] ? this.btnList.filter(v => v.path == item.path)[0].id : null;
        });
      },
      immediate: true,
    },
  },
  // mounted() {
  //     // 根据不同路由跳转不同页面

  //     if (this._isMobile()) {
  //         var main = document.getElementsByClassName('mmdw')[0]
  //         main.style.display = 'flex';
  //     } else {
  //     }
  // },
  methods: {
    // 跳转个人中心
    handleUserCenter() {
      if (this.$route.path == "/my") return;
      this.$router.push({
        path: "/my",
      });
    },

    // 退出登录
    relogin() {
      this.$confirm(`确定要退出登录吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "elConfirmBtn",
      })
        .then(res => {
          rebackLogin().then(res => {
            this.$message.success("您已成功退出登录！");
            this.$store.commit("SETISLOGIN", false);
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            if (this.$route.path == "/my") {
              this.$router.replace({
                path: "/",
              });
            }
          });
        })
        .catch(() => {});
    },

    // 回到首页
    handleGoIndex() {
      if (this.$route.path != "/") {
        this.$router.push({
          path: "/",
        });
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    toPushRouter(item) {
      if (this.$router.currentRoute.path == item.path) {
        return;
      }
      this.idx = item.id;
      //console.log(item);
      this.$router.push({ path: item.path });
    },
    searchProduct() {
      // if (!this.ProductName) return this.$message.warning('请输入产品名称或产品编号')
      if (this.$route.path != "/super") {
        this.$router.push({ path: "/super" });
      }
      this.$store.commit("SETPRODUCTNAME", this.ProductName);
    },
  },
};
</script>

<style lang="scss" scoped>
// 改变 input聚焦颜色
// 输入框 聚焦 边框颜色
.main ::v-deep .el-input.is-active .el-input__inner,
.main ::v-deep .el-input__inner:focus {
  border-color: var(--primary-color) !important;
  outline: 0;
}

.xia {
  border-bottom: 3px solid white;
}

.main {
  min-height: 100vh;
  // width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;

  .header {
    width: 100%;
    //background-color: var(--primary-color);

    background-color: #710606;
    box-sizing: border-box;

    .logo {
    //   margin-top: 10px;
      width: 100%;
      height: 70px;
      font-size: 20px;
      //margin: 0 auto;
      color: white;
      display: flex;
    //   flex-direction: column;
      //position: relative;

      .outLogin {
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;
        color: white;
        font-size: 16px;
      }

      .item {
        display: flex;
        width: 550px;
        justify-content: space-between;
      }
    }
  }

  .body {
    width: 1200px;
    flex-grow: 1;
  }

  .h-copyright-root {
    width: 100%;
    height: 180px;
    // background-color: var(--primary-color);
    background-color: #720606;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 20px;

    .container {
      width: 1200px;
      margin: 0 auto;

      .copyright {
        padding: 46px 0;
        height: 88px;
        font-size: 14px;
        // color: white;
        color: #fff;
        line-height: 22px;
        font-family: -apple-system, "Heiti SC", "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", verdana, sans-serif;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;

        a:hover {
          // color: var(--primary-color) !important;
        }
      }
    }
  }
}
/*搜索组件最外层div */
.input_box {
  border-radius: 95px;
  background: rgba(255, 255, 255, 0.1);
}
/*搜索input框 */
:deep(.el-input__inner) {
  background-color: transparent; /*覆盖原背景颜色，设置成透明 */
  border-radius: 95px;
  border: 0;
  box-shadow: 0 0 0 0px;
}
/*搜索button按钮 */
:deep(.el-input-group__append) {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 95px;
  border: 0;
  box-shadow: 0 0 0 0px;
}
</style>
